<template>
  <div style="padding-bottom: 0px;">
    <v-container fluid v-if="loading">
      <h4>Obteniendo cantidad de Leads por fuente ...</h4>
    </v-container>
    <v-container fluid v-else>
      <div>
        <v-row justify="end" align="center" class="mx-2 px-2">
          <v-btn
            outlined
            class="mx-2"
            v-if="
              filtersSelectedSt &&
                filtersSelectedSt.traffic &&
                filtersSelectedSt.traffic.source.length > 0
            "
            small
            color="primary"
            elevation="0"
            @click="clearFilters()"
          >
            Limpiar
          </v-btn>
          <v-btn
            v-if="
              filtersSelectedSt &&
                filtersSelectedSt.traffic &&
                filtersSelectedSt.traffic.source.length > 0
            "
            small
            color="primary"
            elevation="0"
            @click="filterKanbanWithSourcesSelected()"
          >
            Fitrar con elementos seleccionados
          </v-btn>
        </v-row>
        <v-row justify="center" align="center">
          <div
            v-for="(item, index) in sourceList"
            :key="index"
            style="padding-bottom: 0px;"
          >
            <div
              v-if="
                showOnlySelected &&
                  filtersSelectedSt &&
                  filtersSelectedSt.traffic &&
                  filtersSelectedSt.traffic.source.length > 0
              "
            >
              <div
                v-if="
                  filtersSelectedSt.traffic.source.includes(item.source._id)
                "
                class="ma-2"
              >
                <v-card
                  elevation="0"
                  class="mx-auto"
                  max-width="125"
                  min-width="125"
                  @click="clickFilter(item)"
                  :color="item.selected ? 'grey' : ''"
                  :class="item.selected ? 'white--text' : ''"
                >
                  <div v-if="item.selected">
                    <v-btn icon x-small>
                      <v-icon right>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-row justify="center" align="center" class="pa-0 ma-0">
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                      justify="center"
                      align="center"
                    >
                      <v-img
                        class="ma-2"
                        :src="item.src"
                        width="30"
                        height="30"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                      justify="center"
                      align="center"
                    >
                      <div>
                        {{ item.name }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                      justify="center"
                      align="center"
                    >
                      <p>
                        {{ item.total }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div v-else></div>
            </div>
            <div v-else>
              <div v-if="item.total > 0" class="ma-2">
                <v-card
                  elevation="0"
                  class="mx-auto"
                  max-width="125"
                  min-width="125"
                  @click="clickFilter(item)"
                  :color="item.selected ? 'grey' : ''"
                  :class="item.selected ? 'white--text' : ''"
                >
                  <div v-if="item.selected">
                    <v-btn icon x-small>
                      <v-icon right>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-row justify="center" align="center" class="pa-0 ma-0">
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                      justify="center"
                      align="center"
                    >
                      <v-img
                        class="ma-2"
                        :src="item.src"
                        width="30"
                        height="30"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                      justify="center"
                      align="center"
                    >
                      <div>
                        {{ item.name }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pa-0 ma-0"
                      justify="center"
                      align="center"
                    >
                      <p>
                        {{ item.total }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div v-else></div>
            </div>
          </div>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      showOnlySelected: false,
      sourceClicked: false,
      sourceList: [],
      loading: false,
      clean: false
    };
  },
  computed: {
    ...mapState({
      clearFiltersSt: state => state.leads.clearFilters,
      someFilterSelected: state => state.leads.filterSomeFilterSelected,
      filterHowSelected: state => state.leads.filterHowDidContactUs,
      filtersSelectedSt: state => state.leads.filtersSelected,
      filtersSt: state => state.leads.filters
    }),
    filterHowDidContactUs: {
      get() {
        return this.$store.state.leads.filterHowDidContactUs;
      },
      set(newValue) {
        this.$store.commit("leads/setFilterHowDidContactUs", newValue);
      }
    }
  },
  mounted() {
    this.getSources(null);
  },
  watch: {
    clearFiltersSt(newValue) {
      if (newValue) {
        this.clearFilters();
      }
    },
    filtersSelectedSt(newValue) {
      if (this.clean && !newValue.traffic) {
        this.filterLeadsKanban();
        this.getSourdces(null);
        this.clean = false;
      } else if (newValue.traffic && newValue.traffic.source.length <= 0) {
        this.filterLeadsKanban();
        this.getSourdces(null);
      } else if (newValue.traffic && newValue.traffic.source.length > 0) {
        this.setFilterSelected(this.filtersSelectedSt.traffic.source);
      }
    },

    sourceList() {
      let temp = [];
      const copySourceList = this.sourceList.filter(l => {
        if (l.total > 0) {
          return l;
        }
      });

      if (copySourceList.length > 4) {
        for (let i = 0; i < 4; i++) {
          temp.push(copySourceList[i]);
        }
      } else {
        temp = copySourceList;
      }
      this.$emit("getSource", temp);
    }
  },
  methods: {
    ...mapActions({
      getTrafficDataStore: "getTrafficData",
      fetchGetLeadsKanbanByPhaseSt: "leads/fetchGetLeadsKanbanByPhase"
    }),
    getSources(filter) {
      const self = this;
      self.loading = true;
      this.sourceList = [];
      self.getTrafficDataStore(filter).then(res => {
        for (let i = 0; i < res.source.length; i++) {
          self.sourceList.push({
            selected:
              filter === null
                ? false
                : filter.traffic && filter.traffic.source
                ? filter.traffic.source === res.source[i].source._id
                : false,
            name: res.source[i].source.name,
            src: res.source[i].source.media.src,
            total: res.source[i].leads,
            source: res.source[i].source
          });
        }

        let temp = [];

        if (self.sourceList.length > 4) {
          for (let i = 0; i < 4; i++) {
            temp.push(self.sourceList[i]);
          }
        } else {
          temp = self.sourceList;
        }

        this.$emit("getSource", temp);
        self.loading = false;
      });
    },
    clearFilters() {
      this.filterHowDidContactUs = [];
      for (let i = 0; i < this.sourceList.length; i++) {
        this.sourceList[i].selected = false;
      }
      this.showOnlySelected = false;
      this.clean = true;
    },
    filterKanbanWithSourcesSelected() {
      this.showOnlySelected = true;
      this.filterLeadsKanban();
      this.getSourdces(this.filtersSelectedSt);
    },
    setFilterSelected(list) {
      for (let i = 0; i < this.sourceList.length; i++) {
        if (list.includes(this.sourceList[i].source._id)) {
          this.sourceList[i].selected = true;
        }
      }
    },
    clickFilter(data) {
      for (let i = 0; i < this.sourceList.length; i++) {
        if (this.sourceList[i].source._id === data.source._id) {
          this.sourceList[i].selected = !data.selected;
        }
      }
      if (data) {
        let index = this.filterHowDidContactUs.findIndex(item => {
          return item._id === data.source._id;
        });
        if (index < 0) {
          this.filterHowDidContactUs.push(data.source);
        } else {
          this.filterHowDidContactUs.splice(index, 1);
        }
      } else {
        let final = this.filterHowDidContactUs;
        this.filterHowDidContactUs = final;
      }
    },
    filterLeadsKanban() {
      this.fetchGetLeadsKanbanByPhaseSt({
        filter: this.filtersSelectedSt,
        page: 0,
        phases: this.filtersSt.phases
      }).then(() => {
        this.sourceClicked = false;
      });
    }
  }
};
</script>

<style scoped>
.scroll-kpis {
  overflow-y: scroll;
}
</style>
